<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Accessibility Dashboard
      Author: Bluecrunch
    Author URL: https://www.bluecrunch.com
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" style="max-width: 100%;">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  <vs-input
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="client"
                    type="text"
                    icon-no-border
                    icon="icon icon-box"
                    icon-pack="feather"
                    label-placeholder="Client Code"
                    v-model="client"
                    class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('client') }}</span>

                  <vs-input
                    v-validate="'required|email|min:3'"
                    data-vv-validate-on="blur"
                    name="email"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="email"
                    class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                  <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    type="password"
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Password"
                    v-model="password"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-end my-5">
                    <vs-button :disabled="!validateForm" @click="login">Login</vs-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
    export default{
        data() {
            return {
                email: "",
                password: "",
                client: ""
            }
        },
        computed: {
            validateForm() {
                return !this.errors.any() && this.email && this.password && this.client;
            },
        },
        methods: {
            login() {
                this.$vs.loading();

                const payload = {
                    email: this.email,
                    password: this.password,
                    client: this.client
                }

                this.$store.dispatch("auth/login", payload)
                    .then(() => this.$vs.loading.close())
                    .catch(e => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: 'Error',
                            text: "Error is happened",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
            }
        }
    }
</script>

<style lang="scss"></style>
